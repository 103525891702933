<template>
  <div class="mt-5 mb-14">

    <hr>
    <div class="notify">
      Hello, you are claiming the <b> {{ month }} </b> airdrop <br>
      If you have any questions, please ask in <a target="_blank" class="underline"
                                                  href="https://t.me/dhkdao">Telegram</a>
    </div>
    <hr>
  </div>

</template>

<script>
export default {
  name: "NotifyBlock",
  data() {
    return {
      month: ""
    }
  },
  mounted() {
    const date = new Date();
    this.month = new Date(date.getFullYear(), date.getDate() > 15 ? date.getMonth() : date.getMonth() - 1, 1).toLocaleString('en', {
      month: 'long',
      timeZone: "Asia/Hong_Kong"
    });

  }
}
</script>

<style scoped>
.notify {
  color: #fbe200;
  padding: 10px 0;
}

b {
  text-decoration: underline;
}
</style>