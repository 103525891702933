<template>
  <div class="text-center">
    <ButtonBlock title="Keplr" description="Connect Keplr Wallet">
      <button class="swv-button swv-button-trigger" v-on:click="connectKeplr">
        {{ !keplrWalletAddress['juno'] ? "Connect Keplr" : keplrWalletAddress['juno'].slice(0, 9) }}
      </button>

    </ButtonBlock>

    <ButtonBlock title="Ethereum" description="Connect MetaMask Wallet">
      <button class="swv-button swv-button-trigger" v-on:click="connectETH">
        {{ !keplrWalletAddress['eth'] ? "Connect Wallet" : keplrWalletAddress['eth'].slice(0, 9) }}
      </button>
    </ButtonBlock>

<!--    <ButtonBlock title="Solana" description="Optional, required when you are staking Solana">-->
<!--      <WalletMultiButton></WalletMultiButton>-->
<!--    </ButtonBlock>-->


    <div class="container mx-auto max-w-lg">

      <div class="bg-black shadow-md rounded px-8 pt-6 pb-8 mb-4">

        <div class="mb-4">
          <label class="block text-amber-400 text-sm font-bold mb-2">
            Keplr
          </label>
          <input
              class="bg-black  shadow appearance-none border border-amber-400 rounded w-full py-2 px-3 text-amber-300 leading-tight focus:outline-none focus:shadow-outline"
              id="keplr" type="text" :key="keplrWalletAddress['juno'] ?? 'key'" placeholder="Username" disabled
              :value="keplrWalletAddress['juno'] ?? 'Not Connected'">
        </div>

        <div class="mb-4">
          <label class="block text-amber-400 text-sm font-bold mb-2">
            Ethereum
          </label>
          <input
              class="bg-black  shadow appearance-none border border-amber-400 rounded w-full py-2 px-3 text-amber-300 leading-tight focus:outline-none focus:shadow-outline"
              id="keplr" type="text" :key="keplrWalletAddress['eth'] ?? 'key'" placeholder="Username" disabled
              :value="keplrWalletAddress['eth'] ?? 'Not Connected'">
        </div>

<!--        <div class="mb-4">-->
<!--          <label class="block text-amber-400 text-sm font-bold mb-2">-->
<!--            Solana-->
<!--          </label>-->
<!--          <input-->
<!--              class="bg-black shadow appearance-none border border-amber-400 rounded w-full py-2 px-3 text-amber-300 leading-tight focus:outline-none focus:shadow-outline"-->
<!--              id="solana" type="text" placeholder="Username" disabled-->
<!--              :value=" $wallet?.publicKey.value?.toBase58() ?? solanaWalletAddress ">-->
<!--        </div>-->


        <div class="flex items-center justify-between">
          <div>
            <vueRecaptcha
                :sitekey="v2Sitekey"
                size="normal"
                theme="light"
                hl="zh-TW"
                @verify="recaptchaVerified"
                @expire="recaptchaExpired"
                @fail="recaptchaFailed"
                ref="vueRecaptcha" />
          </div>
          <LoadingButton v-if="loading"/>
          <div v-if="verified && keplrWalletAddress['juno'] && keplrWalletAddress['eth'] && !loading">
            <button class="hover:bg-blue-700 text-white font-bold py-2 px-4 rounded yellow-button"
                    v-on:click="saveWallet">Register
            </button>
          </div>
        </div>
      </div>

    </div>
  </div>
  <Error :show="isError" :title="errorTitle" :closable="closable">{{ errorContent }}</Error>
  <SuccessBlock :show="isSuccess" :title="successTitle" :closable="closable">{{ successContent }}</SuccessBlock>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2';

import {
  SigningCosmosClient
} from "@cosmjs/launchpad";
import axios from "axios";
import ButtonBlock from "@/components/ButtonBlock";
import LoadingButton from "@/components/LoadingButton";
import Error from "./Error.vue"
import {inject, ref, toRefs} from "vue";
import SuccessBlock from "@/components/Success.vue";


export default {
  name: "ConnectButton",
  components: {
    SuccessBlock,
    LoadingButton,
    vueRecaptcha,
    // WalletMultiButton,
    ButtonBlock,
    Error
  },
  setup() {
// Setup Solana Wallet
//     const wallet = useWallet() // eslint-disable-line
    // Setup Store
    const verified = ref(false);
    const v2Sitekey = '6LeUN5gpAAAAAGrLd4wfccJuH9eVxAsOqLF9RqGG';

    // 回傳一組 token，並把 token 傳給後端驗證
    const recaptchaVerified = (res) => {
      console.log({verify: res})
      verified.value = true; // update the state
    }

    const recaptchaExpired = () => {
      // 過期後執行動作
      this.tokenError()
    }

    const recaptchaFailed = () => {
      // 過期後執行動作
      this.tokenError()
    }

    const mapStore = inject("mapStore");
    const {state, showError, showSuccess} = mapStore;
    return {
      ...toRefs(state),
      showError,
      showSuccess,
      v2Sitekey, recaptchaVerified, recaptchaExpired, recaptchaFailed, verified


    };
  },
  async mounted() {
    // this.solanaWallet = useWallet()

    // Handle API
    let urlParams = new URLSearchParams(window.location.search);

    if (!urlParams.has('s')) {
      // this.tokenError()
      return true
    } else {

        return true

      // this.apiKey = (urlParams.get('s'));
      // const config = {
      //   method: 'post',
      //   url: 'https://airdrop.dhk.org/api/solana-wallet?verify=1&key=' + this.apiKey,
      //   headers: {
      //     'Accept': 'application/json, text/plain, */*',
      //     'Content-Type': 'application/json'
      //   },
      // };
      // await axios(config).catch(async () => {
      //   this.tokenError()
      // })
    }

  },
  data() {
    return {
      keplrInited: window.keplr && window.keplr.version,
      loading: false,
      closable: false,
      solanaWallet: undefined,
      solanaWalletAddress: "Not Connected",
      keplrWalletAddress: [],
      apiKey: "",
      localExist:false
    }
  },
  methods: {
    networkError() {
      this.closable = false
      this.showError({
        errorTitle: "Network error",
        errorContent: "Cannot get chain data, Please tell us on Telegram"
      })
    },
    tokenError() {
      this.closable = false
      this.showError({
        errorTitle: "Token is invalid or expired",
        errorContent: "Please request the link again"
      })
    },
    keplrError() {
      this.closable = false
      this.showError({
        errorTitle: "Keplr is missing",
        errorContent: "Please install Keplr"
      })
    },
    registered() {
      this.closable = true
      this.showSuccess({
        successTitle: "Confirmed",
        successContent: "Registration for this month’s airdrop was successful. Thanks."
      })
    },

    async saveWallet() {
      // await this.recaptcha()
      this.loading = true
      // const {publicKey} = useWallet();
      // this.solanaWalletAddress = publicKey.value?.toBase58() ?? 'Not Connected'

      const convertName = {
        "cosmoshub": "cosmos",
        "fetchhub": "fetch",
        "lumnetwork": "lum",
        "sifchain": "sif",
        "osmosis": "osmo",
        "bandchain": "band",
        "bostrom": "boot",
        "gravitybridge": "graviton",
        "microtick": "tick",
        "stargaze":"stars"


      }
      const allWallets = {}
      // if (this.solanaWalletAddress != 'Not Connected') {
      //   allWallets['sol'] = this.solanaWalletAddress
      // }
      for (const x in this.keplrWalletAddress) {
        const walletKey = convertName[x] ?? x
        allWallets[walletKey] = this.keplrWalletAddress[x]
      }

      var config = {
        method: 'post',
        url: 'https://airdrop.dhk.org/api/solana-wallet?key=' + this.apiKey,
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        data: allWallets
      };
      let isNetworkError = false
      let showRegestered = false
      await axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            showRegestered = true

          })
          .catch(function (error) {
            isNetworkError = true
            console.error(error)
          });
      if (isNetworkError) {
        this.tokenError()
      }
      if (showRegestered) {
        this.registered()
      }
      this.loading = false


    },
    async getAddress(chain) {
      // const chainId = "osmosis-1";
      try {
        const {chainId, rpcUrl} = await this.getChainConfig(chain)
        await window.keplr.enable(chainId);
        const offlineSigner = window.getOfflineSigner(chainId);
        const accounts = await offlineSigner.getAccounts();

        // Initialize the gaia api with the offline signer that is injected by Keplr extension.
        const cosmJS = new SigningCosmosClient(
            rpcUrl,
            accounts[0].address,
            offlineSigner,
        );
        console.log(`%cGet ${chainId} ${cosmJS.signerAddress ?? accounts[0].address ?? ""}`, "color:green")
        this.keplrWalletAddress[chain] = cosmJS.signerAddress ?? accounts[0].address ?? ""
      } catch (e) {
        console.warn(e.message)
        // console.warn("Get Chain Error")
      }
    },
    async getChainConfig(type) {
      if(!this.localExist){
        const res = await this.getGithubChainConfig(type)
        return res
      }
      const url = `./chain/${type}.json`
      const axios = require('axios');
      axios.get(url).then(i => {
        console.log(i)
      }).catch(async e => {
        this.localExist = false
        console.log("Error", e)
        const res = await this.getGithubChainConfig(type)
        return res
      })

    },
    async getGithubChainConfig(type) {
      // console.log("Get Chain Config")
      const url = `https://raw.githubusercontent.com/cosmos/chain-registry/master/${type}/chain.json`
      const axios = require('axios');

      const config = {
        method: 'get',
        url: url,
        headers: {}
      };

      let chainId = ""
      let rpcUrl = ""
      let networkErrorAlert = false
      await axios(config)
          .then(function (response) {
            const res = response.data
            chainId = res?.chain_id ?? ""
            rpcUrl = res?.apis?.rpc[0]?.address ?? ""
           })
          .catch(async function (error) {
            networkErrorAlert = true
            console.error("%c" + error, "color:red");
          });
      // console.log(chainId,rpcUrl)
      if (chainId !== "" && rpcUrl !== "") {
        console.log({chainId, rpcUrl})
        return {chainId, rpcUrl}
      }
      if(networkErrorAlert){
        this.networkError()
      }
    },
    async connectETH() {
      console.log("Connect ETH")
      // Connect MetaMask and set the ETH address
      if (window.ethereum) {
        window.ethereum.request({method: 'eth_requestAccounts'})
            .then((accounts) => {
              this.keplrWalletAddress['eth'] =accounts[0]
            })
            .catch((error) => {
              console.error(error)
            });
      } else {
        this.ethError()
      }

    },
    async connectKeplr() {
      if (!window.keplr.version) {
        this.keplrError()
      }

      // https://github.com/cosmos/chain-registry
      await Promise.all(
          [
            this.getAddress("akash"), // AKT
            this.getAddress("bitsong"), // BTSG
            this.getAddress("cerberus"), // Cerberus
            this.getAddress("cheqd"), // CHEQD Network
            this.getAddress("chihuahua"), // HUAHUA
            this.getAddress("comdex"), // Comdex
            this.getAddress("cosmoshub"), // ATOM
            this.getAddress("crescent"), // Crescent Network
            this.getAddress("desmos"), // DSM
            this.getAddress("evmos"), // Evmos
            this.getAddress("juno"), // JUNO
            this.getAddress("kava"),
            this.getAddress("osmosis"), //OSMO
            this.getAddress("provenance"), // Provenance
            this.getAddress("stargaze"), // Stargaze
            this.getAddress("sifchain"), //ROWAN
          ]
      )

      console.log(this.keplrWalletAddress)

    }

  }
}
</script>

<style scoped>

</style>
