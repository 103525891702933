import {createApp} from 'vue';
import {VueReCaptcha} from 'vue-recaptcha-v3'

import App from './App.vue';
import SolanaWallets from 'solana-wallets-vue';
import './index.css'

// You can either import the default styles or create your own.
import 'solana-wallets-vue/styles.css';

import {WalletAdapterNetwork} from "@solana/wallet-adapter-base"

import {
    PhantomWalletAdapter,
    SlopeWalletAdapter,
    SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';

const walletOptions = {
    wallets: [
        new PhantomWalletAdapter(),
        new SlopeWalletAdapter(),
        new SolflareWalletAdapter({network: WalletAdapterNetwork.Devnet}),
    ],
    autoConnect: true,
}

const Vue = createApp(App)
Vue.use(SolanaWallets, walletOptions)
Vue.use(VueReCaptcha, {siteKey: '6LcEN5gpAAAAAArTXQuBoTqHXbgSZwzVjc_yJQgS'})
Vue.mount('#app');