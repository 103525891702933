<template>
<div class="text-amber-300">
  <hr>
  <div class="mb-2"></div>
  Copyright © {{year}} <a href="https://dhk.org/" class="underline">DHK</a>
</div>
</template>

<script>
export default {
  name: "FooterBlock",
  data(){
    return{
      year:""
    }
  },
  mounted() {
    const fromYear = 2022
    const currentYear = new Date().getFullYear()
    this.year = fromYear == currentYear ? currentYear : `${fromYear} - ${currentYear}`

  }
}
</script>

<style scoped>
</style>