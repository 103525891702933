import {reactive, readonly} from "vue";

const state = reactive({
    isError: false,
    errorTitle: "Error",
    errorContent: "Error !!!",
    errorClosable: false,
    isSuccess: false,
    successTitle: "success",
    successContent: "success !!!",
    successClosable: false,
});

const showError = (errorConfig) => {
    const {errorTitle, errorContent} = errorConfig
    state.errorTitle = errorTitle
    state.errorContent = errorContent
    state.isError = true;
};

const showSuccess = (successConfig) => {
    const {successTitle, successContent} = successConfig
    state.successTitle = successTitle
    state.successContent = successContent
    state.isSuccess = true;
};

const closeError = () =>{
    state.isError=false
}

const closeSuccess = () =>{
    state.isSuccess=false
}

export default {
    state: readonly(state),
    showError,
    showSuccess,
    closeError,
    closeSuccess
};