<template>

  <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
       aria-modal="true" v-show="show">
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

      <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <div
          class="relative inline-block align-bottom bg-black rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div class="bg-black px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div
                class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-yellow-400 sm:mx-0 sm:h-10 sm:w-10">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-yellow-400 " id="modal-title">
                {{ title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-yellow-200 ">
                  <slot/>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse" v-if="closable">
          <button type="button"
                  v-on:click="closeSuccess"
                  class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-yellow-400 text-base font-medium text-black hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:ml-3 sm:w-auto sm:text-sm">
            OK
          </button>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject, toRefs} from "vue";

export default {
  name: "SuccessBlock",
  setup() {
    const mapStore = inject("mapStore");
    const {state, closeSuccess} = mapStore;
    return {
      ...toRefs(state),
      closeSuccess,

    };
  },
  props: {
    show: Boolean,
    closable: Boolean,
    title: String
  }
}
</script>

<style scoped>

</style>