<template>
  <img src="@/assets/dhk.png" alt="" class="dhk-logo">
  <NotifyBlock></NotifyBlock>
  <ConnectButton></ConnectButton>
  <Footer/>
  <Error :show="isError" :closable="closable">{{ errorContent }}</Error>

</template>

<script>
import {provide} from "vue";
import store from "@/store";
import ConnectButton from "@/components/ConnectButton.vue";
import NotifyBlock from "@/components/NotifyBlock.vue";
import Footer from "@/components/Footer.vue"
export default {
  name: 'App',
  components: {
    ConnectButton,
    NotifyBlock,
    Footer
  },
  setup() {
    provide("mapStore", store);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
