<template>
  <div class="text-yellow-400 border border-yellow-400 max-w-sm rounded overflow-hidden shadow-lg my-4 mx-auto overflow-visible">
    <div class="px-6 py-4">
      <div class="font-bold text-xl mb-2">{{ title }}</div>
      <p class="text-yellow-500 text-base">
        {{ description }}
      </p>
    </div>
    <div class="px-6 py-4 ">
      <div class="swv-dropdown">
        <slot></slot>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  props: {
    title: String,
    description: String
  },
  setup(props) {
    console.log(props.title)
  }
}
</script>

<style scoped>

</style>